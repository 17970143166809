import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'emotion-flex';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';

import SEO from '../components/SEO';
import EmptyState from '../components/EmptyState';
import Button from '../components/Button';
import { withLayout } from '../components/Layout';
import isDefaultLocale from '../utils/isDefaultLocale';
import { useBrowserLocale } from '../hooks';

const ButtonLink = Button.withComponent(Link);

const NotFoundPage: React.FC<WrappedComponentProps> = ({
  intl: { formatMessage, locale },
}) => {
  useBrowserLocale();
  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: '404.pageNotFoud',
          defaultMessage: 'Page not found',
        })}
      />
      <Container>
        <EmptyState
          title={formatMessage({
            id: '404.pageNotFoud',
            defaultMessage: 'Page not found',
          })}
          text={
            <p>
              <FormattedMessage
                id="404.pageDoesntExit"
                defaultMessage="The page you're looking for doesn&#39;t seem exist..."
              />
            </p>
          }
          action={
            <ButtonLink to={isDefaultLocale(locale) ? '/' : `/${locale}/`}>
              <FormattedMessage id="404.backHome" defaultMessage="Back home" />
            </ButtonLink>
          }
          withImage
        />
      </Container>
    </>
  );
};

export default withLayout(injectIntl(NotFoundPage), { isNewLayout: true });
